@import "./css/normalize.css";
@import "./css/webflow.css";
@import "./css/yomassage.webflow.css";
@import "./css/overrides.css";

@media (max-width: 767px) {
  .footer-container {
    display: none;
    flex-direction: column;
  }
}
