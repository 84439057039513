.form-error {
  margin-top: -5px;
  margin-bottom: 5px;
  font-family: "Cormorant Med", sans-serif;
  color: #c3bdb8;
  margin-left: 2px;
}

.product-info .course-date {
  text-transform: lowercase;
}

.hide-nav {
  opacity: 0;
  pointer-events: none;
  height: 0;
  padding: 0;
}
.body {
  overflow-x: hidden;
}
.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer.shop-page {
  margin-bottom: 0px;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.footer-container.mobile-only {
  display: none;
}

.footer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-col.right {
  width: 250px;
  margin-top: 60px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.footer-col.center {
  width: 670px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-col.left {
  margin-top: 60px;
}

.footer-links {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: "Cormorant Reg", sans-serif;
  color: #595959;
  font-size: 16px;
  text-decoration: none;
}

.footer-links:hover {
  color: hsla(27.27272727272736, 7.05%, 65.57%, 1);
}

.footer-header-left {
  margin-bottom: 80px;
  font-size: 35px;
  text-align: left;
}

.footer.shop-page {
  margin-bottom: 0px;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.footer-container.mobile-only {
  display: none;
}

.footer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-col.right {
  width: 250px;
  margin-top: 60px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.footer-col.center {
  width: 670px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-col.left {
  margin-top: 60px;
}

.footer-links {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: "Cormorant Reg", sans-serif;
  color: #595959;
  font-size: 16px;
  text-decoration: none;
}

.footer-links:hover {
  color: hsla(27.27272727272736, 7.05%, 65.57%, 1);
}

.footer-header-left {
  margin-bottom: 80px;
  font-size: 35px;
  text-align: left;
}

@media (max-width: 991px) {
  .footer {
    margin-top: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer-col.right {
    margin-top: 20px;
  }
  .footer-col.center {
    width: 400px;
  }
  .footer-col.left {
    margin-top: 20px;
  }
  .footer-header-left {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .footer-container > .footer-col.left,
  .footer-container > .footer-col.right,
  .footer-col.right.mobile-only > .footer-col.center.mobile-only {
    display: none;
  }

  .footer-container.mobile-only > .footer-col {
    display: flex;
  }
  .footer {
    margin-top: 140px;
  }
  .footer-container {
    /* 	 display: none;  */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-container.mobile-only {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-col.right.mobile-only {
    width: 50%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .footer-col.center {
    width: 100%;
  }

  .footer-col.center {
    width: 100%;
  }
  .footer-col.center.mobile-only {
    margin-top: 20px;
  }
  .footer-col.left {
    width: 50%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-header-left {
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 2px;
  }
}

@font-face {
  font-family: "Cormorant LI";
  src: url("../fonts/Cormorant-LightItalic.otf");
}

@font-face {
  font-family: "Cormorant MI";
  src: url("../fonts/Cormorant-MediumItalic.otf");
}

@font-face {
  font-family: "Cormorant Italic";
  src: url("../fonts/Cormorant-Italic.otf");
}

@font-face {
  font-family: "Avenir Book";
  src: url("../fonts/Avenir-Book-01.ttf");
}

@media (max-width: 478px) {
  .footer {
    margin-top: 700px;
  }
}

.product-info a {
  color: #333;
}
