.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox:before {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox:after {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

h1 {
  margin-top: 20px;
  margin-bottom: 60px;
  font-family: "Cormorant Reg", sans-serif;
  color: #595959;
  font-size: 55px;
  line-height: 70px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 5px;
  text-transform: lowercase;
}

h2 {
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: "Cormorant LI", sans-serif;
  color: #595959;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 4px;
  text-transform: lowercase;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "Cormorant LI", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Avenir Med", sans-serif;
  color: #c3bdb8;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

p {
  margin-bottom: 10px;
  font-family: "Cormorant Reg", sans-serif;
  font-size: 17px;
}

.menu-bar-desktop {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  max-width: 425px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.pink-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 190px;
  padding: 11px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ecd4c4;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-family: "Avenir Book", sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.pink-button:hover {
  background-color: #d8bca9;
}

.pink-button.overlapping-button {
  position: absolute;
  top: 526px;
  z-index: 8;
}

.pink-button.checkout {
  margin-top: 20px;
}

.brown-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  padding: 11px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #c3bdb8;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-family: "Avenir Book", sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.brown-button:hover {
  background-color: #595959;
}

.brown-button.long {
  width: 220px;
}

.brown-button.long.absolute {
  position: absolute;
  top: 471px;
}

.brown-button.template1 {
  position: absolute;
  left: 155px;
  top: 409px;
}

.brown-button.high-top {
  margin-top: 20px;
}

.home-header {
  height: 620px;
  background-image: url("../images/white-wave-lower.png"),
    url("../images/yomassage-header11400.png");
  background-position: 50% 50%, 50% 100%;
  background-size: cover, cover;
  background-attachment: scroll, fixed;
}

.home-header.template1 {
  background-image: url("../images/white-wave-lower.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot-19.jpg");
  background-image: url("../images/white-wave-lower.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot-19.jpg");
  background-position: 50% 50%, 0px 0px, 50% 50%;
  background-size: cover, auto, cover;
  background-repeat: repeat, repeat, no-repeat;
  background-attachment: scroll, scroll, fixed;
}

.home-header.template2 {
  background-image: url("../images/white-wave-lower.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot-1-6.jpg");
  background-image: url("../images/white-wave-lower.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot-1-6.jpg");
  background-position: 50% 50%, 0px 0px, 50% 50%;
  background-size: cover, auto, cover;
  background-repeat: repeat, repeat, no-repeat;
  background-attachment: scroll, scroll, fixed;
}

.home-header.template3 {
  background-image: url("../images/white-wave-lower.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot-18.jpg");
  background-image: url("../images/white-wave-lower.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot-18.jpg");
  background-position: 50% 50%, 0px 0px, 50% 50%;
  background-size: cover, auto, cover;
  background-attachment: scroll, scroll, fixed;
}

.home-sect-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-left-60 {
  width: 60%;
}

.text-left-60.template2 {
  width: 80%;
}

.text-left-60.template2.small {
  position: relative;
  top: 232px;
  width: 60%;
}

.text-left-60.template3 {
  position: relative;
  left: 356px;
  top: -145px;
}

.text-left-60.template3.small {
  left: 475px;
  width: 45%;
}

.text-left-60.founders {
  width: 100%;
  margin-top: 140px;
}

.max-width-text-left {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.max-width-text-left.template1 {
  margin-top: 180px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.max-width-text-left.template2 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.max-width-text-left.template2.founders {
  padding-bottom: 100px;
}

.h2-left {
  text-align: left;
}

.image-white-border {
  position: absolute;
  left: 23%;
  top: -119%;
  right: 50%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  height: 410px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.image-white-border.left {
  left: 30%;
  top: -34%;
  right: auto;
  bottom: 0%;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  height: 350px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-white-border.left.founders {
  left: 40%;
  top: -40%;
  width: 260px;
  height: 400px;
}

.image-white-border.right {
  left: 65%;
  top: 17%;
  width: 400px;
  height: 590px;
}

.image-white-border.right.template2 {
  top: 26%;
}

.image-white-border.right.template3 {
  left: 1%;
  top: -42%;
}

.image-white-border.home {
  left: 65%;
  top: -70%;
  width: 400px;
  height: 580px;
}

.testimonial-home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-home.template1 {
  margin-top: 220px;
  margin-bottom: 60px;
}

.full-width {
  position: relative;
  width: 100%;
  max-width: 1170px;
}

.full-width.pink {
  padding: 20px 40px;
  background-color: #f7ede7;
}

.full-width.horiz-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 140px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image {
  height: auto;
}

.testimonial-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 550px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(116, 69, 76, 0.2)),
      to(rgba(116, 69, 76, 0.2))
    ),
    url("../images/Yomassage_Brand_Shoot-9.jpg");
  background-image: linear-gradient(
      180deg,
      rgba(116, 69, 76, 0.2),
      rgba(116, 69, 76, 0.2)
    ),
    url("../images/Yomassage_Brand_Shoot-9.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
}

.testimonial-content.template1 {
  height: 650px;
}

.testimonial-text {
  position: absolute;
  max-width: 1170px;
  margin-top: 40px;
  color: #fff;
}

.h1-white {
  margin-bottom: 10px;
  color: #fff;
}

.testimonial-name {
  color: #fff;
}

.yomassage-circle {
  position: relative;
  top: -163px;
  z-index: 6;
}

.as-seen-in {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  margin-bottom: -140px;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-nav {
  display: none;
}

.right-arrow {
  display: none;
}

.left-arrow {
  display: none;
}

.slider {
  background-color: #fff;
}

.upcoming-trainings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.upcoming-trainings-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 22px solid #fff;
  background-color: #f7ede7;
}

.heading-light {
  margin-bottom: 20px;
  color: #c3bdb8;
}

.training-item-home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.register-now-text-link {
  -webkit-transition: color 250ms ease;
  transition: color 250ms ease;
  font-family: "Avenir Book", sans-serif;
  color: #c3bdb8;
  letter-spacing: 3px;
  text-decoration: none;
  text-transform: uppercase;
}

.register-now-text-link:hover {
  color: #595959;
}

.full-width-image {
  height: 500px;
  margin-top: -175px;
  background-image: url("../images/Yomassage_Brand_Shoot-3.jpg");
  background-position: 50% 0%;
  background-size: cover;
  background-attachment: scroll;
}

.full-width-image.template3 {
  background-attachment: fixed;
}

.on-the-blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 600px;
  margin-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.on-the-blog-image-left {
  position: absolute;
  left: 14%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 400px;
  height: 500px;
  background-image: url("../images/Yomassage_Brand_Shoot_Ex-1-2.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.on-the-blog-text {
  position: absolute;
  left: auto;
  top: 0%;
  right: 8%;
  bottom: 0%;
  width: 60%;
  max-width: 600px;
}

.blog-text-snippet {
  width: 100%;
  padding: 40px 20px 40px 40px;
  background-color: #fff;
}

.blog-snippet-title {
  color: #595959;
  text-align: left;
}

.blog-snippet {
  margin-top: 20px;
  color: #595959;
  font-size: 16px;
}

.on-the-blog-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer.shop-page {
  margin-bottom: 0px;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.footer-container.mobile-only {
  display: none;
}

.footer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-col.right {
  width: 250px;
  margin-top: 60px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.footer-col.center {
  width: 670px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-col.left {
  margin-top: 60px;
}

.footer-links {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: "Cormorant Reg", sans-serif;
  color: #595959;
  font-size: 16px;
  text-decoration: none;
}

.footer-links:hover {
  color: #ada7a1;
}

.footer-header-left {
  margin-bottom: 80px;
  font-size: 35px;
  text-align: left;
}

.copyright {
  font-size: 14px;
  text-align: center;
}

.image-2 {
  margin-bottom: 20px;
}

.copyright-link {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #595959;
  text-decoration: none;
  cursor: pointer;
}

.copyright-link:hover {
  color: #ada7a1;
}

.products-wrap-all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -4px;
  margin-bottom: 40px;
  padding: 20px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.product-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  max-width: 300px;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #595959;
  text-decoration: none;
}

.product-block:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #e7e3e0;
  background-color: #f7f7f7;
  box-shadow: 0 1px 13px 0 #e0e0e0;
}

.product-block.pract {
  width: 48%;
  max-width: 600px;
  margin-top: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.product-block.blog {
  width: 48%;
  max-width: 600px;
}

.product-block.founder {
  width: 100%;
  max-width: 1170px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.product-image {
  width: 100%;
  height: 300px;
  background-image: url("../images/black-tshirt-yomassage_590x.png");
  background-position: 50% 50%;
  background-size: cover;
}

.product-name {
  margin-top: 20px;
  font-family: "Cormorant MI", sans-serif;
  font-size: 24px;
}

.product-price {
  margin-top: 10px;
}

.shop-body {
  margin-bottom: 235px;
}

.shop-header {
  position: relative;
  z-index: 999;
  height: 420px;
  background-image: url("../images/white-wave-lower.png"),
    url("../images/Yomassage_Brand_Shoot-37.jpg");
  background-position: 50% 100%, 50% 50%;
  background-size: cover, cover;
  background-repeat: no-repeat, repeat;
  background-attachment: scroll, fixed;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 8888;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.navbar.mobile {
  z-index: 9999;
  display: none;
}

.navbar.hide-nav {
  z-index: 9999;
}

.max-width-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: "Cormorant Med", sans-serif;
  color: #595959;
  font-size: 18px;
}

.nav-link:hover {
  color: #ada7a1;
}

.nav-link.w--current {
  color: #c3bdb8;
}

.icon {
  font-size: 13px;
}

.dropdown-list {
  background-color: #ecd4c4;
}

.dropdown-list.w--open {
  background-color: hsla(0, 0%, 100%, 0.89);
}

.dropdown-link {
  margin-bottom: 5px;
  font-family: "Cormorant Light", sans-serif;
  font-size: 16px;
}

.dropdown-link:hover {
  background-color: rgba(236, 212, 196, 0.33);
}

.dropdown-link.w--current {
  color: #c3bdb8;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 120px;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-body.no-top {
  margin-top: 0px;
}

.product-images {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-3 {
  width: 100%;
}

.image-tabs {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  margin-left: 5px;
  background-image: url("../images/black-tshirt-yomassage_590x.png");
  background-position: 50% 50%;
  background-size: 125px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.image-tabs:hover {
  opacity: 0.53;
}

.image-tab-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block {
  width: 58%;
}

.product-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.product-text-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 58%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-text-info.larger {
  width: 70%;
}

.product-description {
  text-align: center;
}

.quanity-size {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.quantity {
  width: 100%;
  font-family: "Cormorant Reg", sans-serif;
  font-size: 16px;
}

.size {
  width: 49%;
  font-family: "Cormorant Reg", sans-serif;
  font-size: 16px;
}

.color {
  font-family: "Cormorant Reg", sans-serif;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  background-color: #ada7a1;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-family: "Avenir Book", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.submit-button:hover {
  background-color: #595959;
}

.form-block {
  width: 100%;
  max-width: 400px;
  margin-top: 40px;
}

.image-4 {
  position: absolute;
  left: 3%;
  top: 5%;
  right: 0%;
  bottom: 0%;
  z-index: 3;
  max-width: 565px;
}

.image-4.right {
  position: static;
  left: 6%;
  width: 100%;
  max-width: 500px;
}

.image-4.left {
  position: static;
}

.paragraph-centered {
  line-height: 23px;
  text-align: center;
}

.content-area-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 200px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
}

.content-area-2.template2 {
  margin-bottom: 350px;
}

.content-area-2.template2.founders {
  margin-bottom: 140px;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button {
  position: absolute;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 40px;
  padding-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-wrap.sign-up {
  padding-top: 100px;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-form.join {
  width: 650px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.find-event-header {
  height: 350px;
  background-image: url("../images/white-wave-22000.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot-18.jpg");
  background-image: url("../images/white-wave-22000.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot-18.jpg");
  background-position: 50% 100%, 0px 0px, 50% 50%;
  background-size: cover, auto, cover;
  background-repeat: no-repeat, repeat, no-repeat;
  background-attachment: scroll, scroll, fixed;
}

.find-event-header.find-class {
  background-image: url("../images/white-wave2000.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot_Ex-1.jpg");
  background-image: url("../images/white-wave2000.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot_Ex-1.jpg");
  background-position: 50% 100%, 0px 0px, 50% 100%;
  background-size: cover, auto, cover;
}

.find-event {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.event-wrapper {
  width: 100%;
  max-width: 1170px;
  padding: 20px;
  font-family: "Cormorant Med", sans-serif;
}

.shopping-bag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
}

.shopping-bag:hover {
  opacity: 0.73;
}

.my-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  margin-bottom: 40px;
  padding: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.product-cart {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  margin-top: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #eeebe8;
}

.product-image-cart {
  width: 150px;
  height: 150px;
  background-image: url("../images/black-tshirt-yomassage_590x.png");
  background-position: 50% 50%;
  background-size: cover;
}

.remove {
  position: absolute;
  left: auto;
  top: auto;
  right: 4%;
  bottom: 0%;
  height: 30px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "Cormorant MI", sans-serif;
  color: #595959;
}

.remove:hover {
  color: #ada7a1;
}

.products-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.info-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.total {
  text-align: center;
}

.practitioner-index {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.member-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  padding: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.profile-image {
  width: 100%;
  height: 300px;
  background-image: url("../images/Yomassage_Brand_Shoot_Ex-1-2.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.profile-image.pract {
  height: 320px;
}

.profile-image.founder {
  height: 100%;
  max-width: 300px;
  min-width: 300px;
}

.pract-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pract-bio {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-left: 15px;
  font-size: 15px;
  text-align: center;
}

.pract-email {
  font-family: "Cormorant Italic", sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
}

.password-field {
  height: auto;
  font-family: "Cormorant Med", sans-serif;
}

.password-field.large {
  height: 120px;
}

._404-error-text {
  max-width: 1170px;
  text-align: center;
}

.contact-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-form-wrapper {
  width: 100%;
  max-width: 1170px;
  padding-right: 20px;
  padding-left: 20px;
}

.name-email-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.name-email {
  width: 49%;
  font-family: "Cormorant Reg", sans-serif;
}

.text-field-2 {
  width: 49%;
}

.text-area {
  font-family: "Cormorant Med", sans-serif;
}

.paragraph {
  text-align: center;
}

.form-block-2 {
  margin-top: 40px;
}

.blog-header {
  height: 425px;
  background-image: url("../images/white-wave-22000.png"),
    -webkit-gradient(linear, left top, left bottom, from(rgba(116, 69, 76, 0.2)), to(rgba(116, 69, 76, 0.2))),
    url("../images/Yomassage_Brand_Shoot-8.jpg");
  background-image: url("../images/white-wave-22000.png"),
    linear-gradient(180deg, rgba(116, 69, 76, 0.2), rgba(116, 69, 76, 0.2)),
    url("../images/Yomassage_Brand_Shoot-8.jpg");
  background-position: 50% 100%, 0px 0px, 50% 50%;
  background-size: cover, auto, cover;
}

.blog-excerpt {
  margin-right: 15px;
  margin-left: 15px;
  font-size: 15px;
  text-align: center;
}

.blog-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1170px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-individ-header {
  height: 450px;
  background-image: url("../images/white-wave-lower.png"),
    url("../images/Yomassage_Brand_Shoot_Ex-1.jpg");
  background-position: 50% 100%, 50% 50%;
  background-size: cover, cover;
  background-attachment: scroll, fixed;
}

.blog-post {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-wrapper {
  position: absolute;
  top: -179px;
  width: 100%;
  max-width: 1170px;
  padding: 20px;
  background-color: #f7ede7;
  font-family: "Cormorant Reg", sans-serif;
}

.blog-cat-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.date {
  font-family: "Avenir Book", sans-serif;
  color: #c3bdb8;
  font-size: 14px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.blog-text {
  margin-top: 20px;
}

.blog-content {
  padding: 20px;
  background-color: #fff;
}

.prev-next {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.on-the-blog-100 {
  width: 100%;
}

.blog-paragraph {
  font-size: 16.5px;
  text-align: left;
}

.log-in-sign-up {
  line-height: 25px;
  text-align: center;
}

.members-only-heading-area {
  margin-bottom: 40px;
}

.members-only-heading {
  margin-bottom: 20px;
}

._12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mailchimp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h1-low-margin {
  margin-bottom: 20px;
  font-family: "Avenir Book", sans-serif;
  font-size: 35px;
  text-transform: uppercase;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mailchimp12 {
  width: 49%;
  font-family: "Cormorant Med", sans-serif;
}

.mailchimp-form {
  margin-top: 40px;
}

.success-message {
  background-color: #fff;
  font-family: "Cormorant Med", sans-serif;
}

.error-message {
  background-color: #595959;
  font-family: "Cormorant Med", sans-serif;
  color: #f7ede7;
}

.founder-text {
  padding-right: 20px;
  padding-left: 20px;
}

.founder-bio {
  font-size: 16px;
}

.as-seen-in-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.mailchimp1 {
  width: 100%;
  font-family: "Cormorant Med", sans-serif;
}

.downloads-header {
  height: 550px;
  background-image: url("../images/white-wave2000.png"),
    url("../images/Yomassage_Brand_Shoot-37.jpg");
  background-position: 50% 100%, 50% 50%;
  background-size: cover, cover;
}

.member-downloads {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.downloadable-sections-wrapper {
  width: 100%;
  max-width: 1170px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.downloadable-categories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.downloadable-categories.high-top {
  margin-top: 40px;
  margin-bottom: 100px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.downloadable-category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filterer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filterer-form {
  width: 400px;
}

.select {
  width: 200px;
  margin-bottom: 0px;
  font-family: "Cormorant Med", sans-serif;
}

.low-bottom {
  margin-bottom: 19px;
}

.high-bottom {
  margin-bottom: 20px;
}

.downloadable-product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.high-top {
  margin-top: 40px;
}

.video-block {
  width: 49%;
  margin-bottom: 10px;
}

.options-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49.5%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f1f1f1;
}

.option.option2 {
  background-color: #f7efe9;
}

.higher-bottom {
  margin-bottom: 40px;
}

.highest-bottom {
  margin-bottom: 46px;
}

.text-field {
  font-family: "Cormorant Med", sans-serif;
}

.checkbox {
  font-family: "Cormorant Med", sans-serif;
}

@media (max-width: 991px) {
  .menu-bar-desktop {
    display: none;
  }
  .menu-buttons.mobile {
    width: 20%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .brown-button.template1 {
    left: 93px;
  }
  .text-left-60 {
    width: 100%;
    margin-top: 40px;
  }
  .text-left-60.template1 {
    margin-top: 0px;
  }
  .max-width-text-left {
    padding-right: 40px;
    padding-left: 40px;
  }
  .image-white-border {
    left: 11%;
    top: -117%;
    width: 600px;
    height: 430px;
  }
  .image-white-border.left {
    left: 16%;
    top: -30%;
  }
  .image-white-border.left.founders {
    left: 31%;
    top: -32%;
  }
  .image-white-border.right {
    left: 44%;
    top: 88%;
  }
  .image-white-border.right.template2 {
    left: 61%;
    top: 52%;
    width: 300px;
    height: 440px;
  }
  .testimonial-home {
    margin-top: 600px;
  }
  .full-width-image {
    margin-top: -66px;
  }
  .on-the-blog-image-left {
    left: 5%;
  }
  .on-the-blog-text {
    right: 2%;
    width: 50%;
  }
  .blog-text-snippet {
    width: 100%;
    padding-right: 40px;
  }
  .footer {
    margin-top: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer-col.right {
    margin-top: 20px;
  }
  .footer-col.center {
    width: 400px;
  }
  .footer-col.left {
    margin-top: 20px;
  }
  .footer-header-left {
    font-size: 30px;
  }
  .copyright {
    font-size: 13px;
  }
  .product-wrapper {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .product-block {
    width: 48%;
  }
  .navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
  }
  .navbar.mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar.hide-nav {
    display: none;
  }
  .nav-link {
    background-color: #fff;
    -webkit-transition: all 300ms ease, color 300ms ease;
    transition: all 300ms ease, color 300ms ease;
  }
  .nav-link:hover {
    background-color: #ada7a1;
    color: #fff;
  }
  .menu-button {
    background-color: #fff;
  }
  .menu-button.w--open {
    background-color: #fff;
    color: #ada7a1;
  }
  .image-tabs {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .image-tab-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .product-text-info {
    padding-right: 10px;
    padding-left: 10px;
  }
  .icon-2 {
    color: #ada7a1;
  }
  .my-cart {
    margin-top: 40px;
  }
  .product-cart {
    width: 100%;
  }
  .products-left {
    width: 50%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .info-right {
    width: 50%;
  }
  .blog-post {
    min-height: 700px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .post-wrapper {
    width: 90%;
    margin-right: 10px;
    margin-left: 0px;
  }
  .mailchimp {
    margin-top: 500px;
  }
  .downloadable-categories {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .downloadable-category {
    width: 49%;
    margin-bottom: 20px;
  }
  .options-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .option {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .brown-button.long.absolute {
    top: 543px;
  }
  .text-left-60.template2.small {
    top: 199px;
    width: 100%;
  }
  .image-white-border.left.founders {
    left: 24%;
    top: -24%;
  }
  .image-white-border.right.template2 {
    left: 24%;
    top: 154%;
  }
  .upcoming-trainings-block {
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .heading-light {
    font-size: 50px;
  }
  .footer {
    margin-top: 140px;
  }
  .footer-container {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-container.mobile-only {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-col.right.mobile-only {
    width: 50%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .footer-col.center {
    width: 100%;
  }
  .footer-col.center.mobile-only {
    margin-top: 20px;
  }
  .footer-col.left {
    width: 50%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-header-left {
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .product-block.pract {
    width: 100%;
  }
  .product-block.blog {
    width: 100%;
  }
  .product-block.founder {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .product-body {
    margin-top: 20px;
  }
  .product-images {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-3 {
    width: 60%;
  }
  .product-info {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .product-text-info {
    width: 100%;
    margin-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .product-text-info.larger {
    width: 90%;
  }
  .content-area-2.template2 {
    margin-bottom: 700px;
  }
  .utility-page-wrap {
    height: 40vh;
    margin-bottom: -114px;
  }
  .utility-page-wrap.sign-up {
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .utility-page-content {
    width: 400px;
  }
  .utility-page-form {
    width: 400px;
  }
  .utility-page-form.join {
    width: 100%;
  }
  .my-cart {
    margin-top: 10px;
  }
  .cart-wrapper {
    margin-bottom: -117px;
    padding-top: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .products-left {
    width: 80%;
  }
  .info-right {
    width: 100%;
    margin-bottom: 20px;
  }
  .profile-image.founder {
    max-width: 100%;
    min-height: 300px;
  }
}

@media (max-width: 479px) {
  h1 {
    font-size: 45px;
  }
  .menu-bar-desktop {
    display: none;
  }
  .menu-buttons {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .menu-buttons.mobile {
    width: 50%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .brown-button.long.absolute {
    top: 594px;
  }
  .brown-button.template1 {
    left: 55px;
    top: 524px;
    z-index: 99;
  }
  .home-header.template2.founders {
    background-image: url("../images/white-wave-lower.png"),
      -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)),
      url("../images/CROP-YoMa_Finals-73.jpg");
    background-image: url("../images/white-wave-lower.png"),
      linear-gradient(180deg, transparent, transparent),
      url("../images/CROP-YoMa_Finals-73.jpg");
    background-size: cover, auto, cover;
  }
  .text-left-60 {
    width: 100%;
  }
  .text-left-60.founders {
    margin-top: 40px;
  }
  .max-width-text-left {
    padding-right: 20px;
    padding-left: 20px;
  }
  .image-white-border {
    top: -66%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 250px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-white-border.left {
    left: 0%;
    top: -33%;
    width: 100%;
    height: 250px;
  }
  .image-white-border.left.founders {
    left: 18%;
    top: -12%;
    display: none;
    width: 240px;
    height: 400px;
  }
  .image-white-border.right {
    left: 28%;
    top: 94%;
    display: block;
  }
  .testimonial-home {
    margin-top: 276px;
  }
  .testimonial-home.template1 {
    margin-top: 100px;
    margin-bottom: -245px;
  }
  .full-width.horiz-flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .upcoming-trainings-block {
    padding-right: 10px;
    padding-left: 10px;
  }
  .heading-light {
    margin-top: 10px;
    font-size: 45px;
    line-height: 60px;
  }
  .training-item-home {
    width: 100%;
  }
  .on-the-blog-image-left {
    left: 0%;
    max-width: 320px;
  }
  .on-the-blog-text {
    right: 0%;
    width: 100%;
    margin-top: 393px;
    background-color: #fff;
  }
  .blog-text-snippet {
    padding-top: 20px;
  }
  .footer {
    margin-top: 420px;
    border-top: 1px solid #f7ede7;
  }
  .footer.shop-page {
    margin-top: 0px;
  }
  .footer-container.mobile-only {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-col.right.mobile-only {
    width: 49%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .footer-col.center.mobile-only {
    margin-top: 20px;
  }
  .footer-col.left {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-header-left {
    margin-bottom: 20px;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .product-block {
    width: 100%;
  }
  .product-name.pract {
    text-align: center;
  }
  .shop-header {
    position: static;
  }
  .nav-link {
    background-color: #fff;
    -webkit-transition: background-color 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, color 300ms ease;
  }
  .nav-link:hover {
    background-color: #ada7a1;
    color: #fff;
  }
  .menu-button {
    padding-right: 10px;
    padding-left: 10px;
    color: #c3bdb8;
  }
  .menu-button.w--open {
    padding-right: 10px;
    padding-left: 10px;
    background-color: #fff;
    color: #c3bdb8;
  }
  .nav-menu {
    background-color: #fff;
  }
  .image-tabs {
    width: 60px;
    height: 60px;
    background-size: cover;
  }
  .product-text-info {
    margin-bottom: -375px;
  }
  .product-text-info.larger {
    width: 100%;
  }
  .image-4 {
    position: absolute;
    left: 4%;
    width: 320px;
  }
  .image-4.left {
    max-width: 100%;
  }
  .image-4.left.founders {
    width: 300px;
    max-width: 200px;
  }
  .content-area-2 {
    margin-top: 60px;
  }
  .content-area-2.template2 {
    margin-bottom: 300px;
  }
  .content-area-2.template2.founders {
    margin-top: 10px;
  }
  .utility-page-wrap {
    margin-bottom: -301px;
    padding-top: 100px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .utility-page-wrap.sign-up {
    margin-top: 140px;
    margin-bottom: 0px;
  }
  .utility-page-content {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .utility-page-form {
    width: 100%;
  }
  .utility-page-form.join {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cart-wrapper {
    margin-bottom: -398px;
  }
  .products-left {
    width: 100%;
  }
  .member-wrapper {
    margin-top: -87px;
  }
  .contact-wrapper {
    margin-top: 20px;
    margin-bottom: -318px;
  }
  .blog-post {
    min-height: 1200px;
  }
  .post-wrapper {
    width: 95%;
  }
  ._12 {
    width: 100%;
  }
  .mailchimp {
    margin-top: 510px;
  }
  .h1-low-margin {
    font-size: 20px;
    line-height: 45px;
  }
  .as-seen-in-item {
    width: 30%;
  }
  .downloadable-categories {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .downloadable-category {
    width: 90%;
  }
}

@font-face {
  font-family: "Avenir Black";
  src: url("../fonts/Avenir-Black-03.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Light";
  src: url("../fonts/Avenir-Light-07.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Book";
  src: url("../fonts/Avenir-Book-01.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Med";
  src: url("../fonts/Avenir-Medium-09.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant sB";
  src: url("../fonts/Cormorant-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant MI";
  src: url("../fonts/Cormorant-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Cormorant Bold";
  src: url("../fonts/Cormorant-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant Light";
  src: url("../fonts/Cormorant-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant Italic";
  src: url("../fonts/Cormorant-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Cormorant BI";
  src: url("../fonts/Cormorant-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Cormorant Med";
  src: url("../fonts/Cormorant-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant LI";
  src: url("../fonts/Cormorant-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Cormorant sBI";
  src: url("../fonts/Cormorant-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Cormorant Reg";
  src: url("../fonts/Cormorant-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
